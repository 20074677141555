import { memo } from "react";
import "./OcrStep.scss";
import { Button, Heading, Text } from "@chakra-ui/react";
import { Card, Col, Divider, Row } from "antd";
import Navbar from "components/Navbar/Navbar";
import { useDispatch, useSelector } from "react-redux";
import {
  setFormTrackInfo,
  setNewNaturalStep,
} from "redux/features/newNaturalClient";
import { confirmOcrData } from "service/newNaturalClient/formOne";
import ErrorCToast from "components/ErrorToast/ErrorCToast";
const OcrStep = memo(() => {
  const { trackData } = useSelector((store) => store.newNaturalClient);
  const dispatch = useDispatch();

  const handleCheckOCRData = async (validation) => {
    const body = { validation };
    try {
      const res = await confirmOcrData(body);
      if (res.data.message === "Datos marcados como incorrectos") {
        dispatch(setFormTrackInfo({ ...res.data, bad_data: true }));
        dispatch(setNewNaturalStep(3));
        return;
      }
      dispatch(setFormTrackInfo(res.data));
      dispatch(setNewNaturalStep(parseInt(res.data.nextView)));
    } catch (error) {
      ErrorCToast(error);
    }
  };

  return (
    <div className="ocr-step">
      <Navbar title="Confirma tus datos" />
      <Card
        title={
          <Heading
            as="h4"
            fontSize={{ base: "md", sm: "md", md: "md", lg: "md", xl: "md" }}
            color={"cBlueGray.700"}
            style={{ margin: "0" }}
          >
            ¿Son correctos estos datos personales?
          </Heading>
        }
      >
        <Row gutter={[16, 16]} >
          <Col span={24}>
            <Row justify="space-between">
              <Col span={12}>
                <Text
                textAlign="start"
                  fontSize={{
                    base: "sm",
                    sm: "sm",
                    md: "sm",
                    lg: "sm",
                    xl: "sm",
                  }}
                  color={"cBlueGray.500"}
                  style={{ margin: "0"}}
                >
                  ID Documento 
                </Text>
              </Col>
              <Col span={12}>
                <Row justify={"end"}>{trackData.data.idDocument}</Row>
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            <Row justify="space-between">
              <Col span={12}>
                <Text
                textAlign="start"
                  fontSize={{
                    base: "sm",
                    sm: "sm",
                    md: "sm",
                    lg: "sm",
                    xl: "sm",
                  }}
                  color={"cBlueGray.500"}
                  style={{ margin: "0" }}
                >
                  Expedida
                </Text>
              </Col>
              <Col span={12}>
                <Row justify={"end"}>{trackData.data.expeditionDate}</Row>
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            <Row justify="space-between">
              <Col span={12}>
                <Text
                textAlign="start"
                  fontSize={{
                    base: "sm",
                    sm: "sm",
                    md: "sm",
                    lg: "sm",
                    xl: "sm",
                  }}
                  color={"cBlueGray.500"}
                  style={{ margin: "0" }}
                >
                  Expira
                </Text>
              </Col>
              <Col span={12}>
                <Row justify={"end"}>{trackData.data.expirationDate}</Row>
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            <Row justify="space-between">
              <Col span={12}>
                <Text
                textAlign="start"
                  fontSize={{
                    base: "sm",
                    sm: "sm",
                    md: "sm",
                    lg: "sm",
                    xl: "sm",
                  }}
                  color={"cBlueGray.500"}
                  style={{ margin: "0" }}
                >
                  Nombres
                </Text>
              </Col>
              <Col span={12}>
                <Row justify={"end"}>{trackData.data.names}</Row>
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            <Row justify="space-between">
              <Col span={12}>
                <Text
                textAlign="start"
                  fontSize={{
                    base: "sm",
                    sm: "sm",
                    md: "sm",
                    lg: "sm",
                    xl: "sm",
                  }}
                  color={"cBlueGray.500"}
                  style={{ margin: "0" }}
                >
                  Apellidos
                </Text>
              </Col>
              <Col span={12}>
                <Row justify={"end"}>{trackData.data.lastNames}</Row>
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            <Row justify="space-between">
              <Col span={12}>
                <Text
                textAlign="start"
                  fontSize={{
                    base: "sm",
                    sm: "sm",
                    md: "sm",
                    lg: "sm",
                    xl: "sm",
                  }}
                  color={"cBlueGray.500"}
                  style={{ margin: "0" }}
                >
                  F. Nacimiento
                </Text>
              </Col>
              <Col span={12}>
                <Row justify={"end"}>{trackData.data.birthday}</Row>
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            <Row justify="space-between">
              <Col span={12}>
                <Text
                textAlign="start"
                  fontSize={{
                    base: "sm",
                    sm: "sm",
                    md: "sm",
                    lg: "sm",
                    xl: "sm",
                  }}
                  color={"cBlueGray.500"}
                  style={{ margin: "0" }}
                >
                  Nacionalidad
                </Text>
              </Col>
              <Col span={12}>
                <Row justify={"end"}>{trackData.data.nationality}</Row>
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            <Row justify="space-between">
              <Col span={12}>
                <Text
                textAlign="start"
                  fontSize={{
                    base: "sm",
                    sm: "sm",
                    md: "sm",
                    lg: "sm",
                    xl: "sm",
                  }}
                  color={"cBlueGray.500"}
                  style={{ margin: "0" }}
                >
                  País de Nac.
                </Text>
              </Col>
              <Col span={12}>
                <Row justify={"end"}>{trackData.data.placeOfBirth}</Row>
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            <Row justify="space-between">
              <Col span={12}>
                <Text
                textAlign="start"
                  fontSize={{
                    base: "sm",
                    sm: "sm",
                    md: "sm",
                    lg: "sm",
                    xl: "sm",
                  }}
                  color={"cBlueGray.500"}
                  style={{ margin: "0" }}
                >
                  Sexo
                </Text>
              </Col>
              <Col span={12}>
                <Row justify={"end"}>{trackData.data.gender}</Row>
              </Col>
            </Row>
          </Col>

          <Divider style={{ margin: "0" }} />

          <Col span={24}>
            <Row justify="center">
              <Heading
                as="h5"
                fontSize={{
                  base: "sm",
                  sm: "sm",
                  md: "sm",
                  lg: "sm",
                  xl: "sm",
                }}
                style={{ margin: "0" }}
                onClick={() => handleCheckOCRData(false)}
                /*  onClick={() => dispatch(setNewNaturalStep(3))} */
              >
                ¿Hay algo mal con tus datos?
                <span className="link"> Click aquí</span>
              </Heading>
            </Row>
          </Col>
        </Row>
      </Card>
      <div className="one-button-footer background">
        <Button
          size={{ base: "md", sm: "md", md: "lg", lg: "lg", xl: "lg" }}
          fontSize={{ base: "sm", sm: "sm", md: "sm", lg: "sm", xl: "sm" }}
          fontWeight="600"
          bg="UniOrange.600"
          colorScheme="UniOrange"
          color="UniOrange.50"
          onClick={() => handleCheckOCRData(true)}
          id="submit-button"
          /*      isDisabled={!allInputFilled({...personalValues,...homeAdress,...kycClient})} */
        >
          Sí, son correctos
        </Button>
      </div>
    </div>
  );
});
OcrStep.displayName = "OcrStep";
export default OcrStep;
