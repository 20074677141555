import { memo } from "react";
import LoginForm from "./components/LoginForm/LoginForm";
import MainTemplate from "templates/MainTemplate/MainTemplate";
const Login = memo(() => {
  return (
    <MainTemplate title="¡Bienvenido a Cooperativa!">
      <LoginForm />
    </MainTemplate>
  );
});
Login.displayName = "Login";
export default Login;
