import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setFormTrackInfo,
  setNewNaturalStep,
} from "redux/features/newNaturalClient";
import { structureInfoFromBack } from "../../PepDataStep/hooks/usePepDataStep";
import {
  getRiskActivity,
  getSalaryRange,
  postLaboralData,
} from "service/newNaturalClient/formFour";
import { handleErrors } from "helpers/handleErrors";
import { getAllValuesFromForm } from "helpers/getAllValuesFromForm";

export const activityOptions = (activities) => {
  const cleanProvinces = activities.map((res) => {
    return { label: res.nameActivity, value: res.id };
  });
  return cleanProvinces;
};

export const incomingOptions = (incomings) => {
  const cleanProvinces = incomings.map((res) => {
    return { label: res.name, value: res.id };
  });
  return cleanProvinces;
};
export const useLaboralDataHook = () => {
  const { trackData } = useSelector((store) => store.newNaturalClient);
  const initialState = {
    activity: {
      label: "Ocupación",
      placeholder: "Seleccione",
      name: "activity",
      value: "",
      required: false,
      error: "",
      visibility: "",
      type: "",
      options: [],
      rule: (value) => {
        if (value === "") return "Debe de completar este campo";
        return "";
      },
    },
    companyWork: {
      label: "Empresa donde trabaja",
      placeholder: "Empresa",
      name: "companyWork",
      value: "",
      required: false,
      error: "",
      visibility: "",
      type: "text",
      rule: (value) => {
        if (value === "") return "Debe de completar este campo";
        if (value.length > 250)
          return "Este campo solo puede tener un máximo de 250 caracteres";
        return "";
      },
    },
    amount: {
      label: "Salario mensual (rango)",
      placeholder: "Seleccione",
      name: "amount",
      value: "",
      required: false,
      error: "",
      visibility: "",
      type: "number",
      disabled: false,
      options: [],
      rule: (value) => {
        if (value === "") return "Debe de completar este campo";
        return "";
      },
    },
    otherIncome: {
      label: "¿Posee otros ingresos adicionales?",
      placeholder: "",
      name: "otherIncome",
      value: "",
      required: false,
      error: "",
      visibility: "",
      type: "radio",
      options: [
        { label: "Si", value: "yes" },
        { label: "No", value: "no" },
      ],
      rule: (value) => {
        if (value === "") return "Debe de completar este campo";
        return "";
      },
    },
    otherAmount: {
      label: "Ingresos adicionales (mensual)",
      placeholder: "Seleccione un rango",
      name: "otherAmount",
      value: "",
      required: false,
      error: "",
      visibility: "",
      type: "radio",
      disabled: true,
      options: [],
    },
  };
  const [laboralData, setlaboralData] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const handleGetInitialState = async () => {
      try {
        const result = await getRiskActivity();
        const result2 = await getSalaryRange();
        setlaboralData((prev) => {
          const { activity, amount, otherAmount } = prev;
          return {
            ...prev,
            activity: {
              ...activity,
              options: activityOptions(result.data),
            },
            amount: {
              ...amount,
              options: incomingOptions(result2.data),
            },
            otherAmount: {
              ...otherAmount,
              options: incomingOptions(result2.data),
            },
          };
        });
      } catch (error) {
        handleErrors(error);
      }
    };

    handleGetInitialState();
    return () => {
      setlaboralData(initialState);
    };
  }, []);

  useEffect(() => {
    const handleInitialStateInfo = () => {
      const formPersonal = structureInfoFromBack(trackData, initialState);
      setlaboralData(formPersonal);
    };
    if (Object.keys(trackData.data).length > 1) handleInitialStateInfo();
  }, [trackData.data]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const body = {
      ...getAllValuesFromForm(laboralData),
      otherIncome: laboralData.otherIncome.value === "yes",
    };
    try {
      const res = await postLaboralData(body);
      dispatch(setFormTrackInfo(res.data));
      dispatch(setNewNaturalStep(parseInt(res.data.nextView)));
      navigate(".", { state: res.data });
    } catch (error) {
      handleErrors(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setlaboralData((prev) => {
      const inputObject = prev.otherAmount;
      inputObject.disabled = laboralData.otherIncome.value === "no";
      let newData = { ...prev, otherAmount: inputObject };

      if (laboralData.otherIncome.value === "no") {
        newData = {
          ...prev,
          otherAmount: {
            ...inputObject,
            value: inputObject.value ?? null,
            rule: () => {
              return "";
            },
          },
        };
      } else {
        newData = {
          ...prev,
          otherAmount: {
            ...inputObject,
            value: inputObject.value ?? "",
            rule: (value) => {
              if (value === "") return "Debe de completar este campo";
              return "";
            },
          },
        };
      }
      return newData;
    });
    window.scrollTo(0, 0);
  }, [laboralData.otherIncome.value]);

  return {
    laboralData,
    setlaboralData,
    handleSubmit,
    loading,
  };
};
