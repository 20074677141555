import { memo } from "react";
import { Button, Heading } from "@chakra-ui/react";
import { Card } from "antd";
import { useLaboralDataHook } from "../hooks/useLaboralDataHook";
import CSelect from "components/inputs/Select/CSelect";
import CInput from "components/inputs/Input/CInput";
import CRadioInput from "components/inputs/RadioInput/CRadioInput";
import { allInputFilled } from "helpers/validateAllInputsFilled";
import Navbar from "components/Navbar/Navbar";
import CSelectWithModal from "components/inputs/SelectWithModal/CSelectWithModal";

const LaboralDataForm = memo(() => {
  const { laboralData, setlaboralData, loading, handleSubmit } =
    useLaboralDataHook();

  return (
    <form className="form" onSubmit={handleSubmit}>
      <Navbar title="Datos laborales" />

      <Card
        style={{ marginBottom: "36px" }}
        title={
          <Heading
            as="h4"
            fontSize={{ base: "lg", sm: "lg", md: "lg", lg: "lg", xl: "lg" }}
            color={"cBlueGray.700"}
            style={{ margin: "0" }}
          >
          Ingrese su información laboral 
          </Heading>
        }
      >
        <div className="row wrap">
          <div className="col">
            <CSelectWithModal
              label={laboralData.activity.label}
              name={laboralData.activity.name}
              value={laboralData.activity.value}
              onChange={setlaboralData}
              placeholder={laboralData.activity.placeholder}
              error={laboralData.activity.error}
              options={laboralData.activity.options}
              rule={laboralData.activity.rule}
            />
          </div>
          <div className="col">
            <CInput
              label={laboralData.companyWork.label}
              name={laboralData.companyWork.name}
              value={laboralData.companyWork.value}
              onChange={setlaboralData}
              placeholder={laboralData.companyWork.placeholder}
              error={laboralData.companyWork.error}
              type={laboralData.companyWork.type}
              rule={laboralData.companyWork.rule}
            />
          </div>
          <div className="col">
            <CSelect
              label={laboralData.amount.label}
              name={laboralData.amount.name}
              value={laboralData.amount.value}
              onChange={setlaboralData}
              placeholder={laboralData.amount.placeholder}
              error={laboralData.amount.error}
              type={laboralData.amount.type}
              disabled={laboralData.amount.disabled}
              options={laboralData.amount.options}
              rule={laboralData.amount.rule}
            />
          </div>

          <div className="col">
            <CRadioInput
              label={laboralData.otherIncome.label}
              name={laboralData.otherIncome.name}
              value={laboralData.otherIncome.value}
              onChange={setlaboralData}
              placeholder={laboralData.otherIncome.placeholder}
              error={laboralData.otherIncome.error}
              disabled={laboralData.otherIncome.disabled}
              radioOptions={laboralData.otherIncome.options}
              rule={laboralData.otherIncome.rule}
            />
          </div>
          {laboralData.otherIncome.value === "yes" && (
            <div className="col">
              <CSelect
                label={laboralData.otherAmount.label}
                name={laboralData.otherAmount.name}
                value={laboralData.otherAmount.value}
                onChange={setlaboralData}
                placeholder={laboralData.otherAmount.placeholder}
                error={laboralData.otherAmount.error}
                disabled={laboralData.otherAmount.disabled}
                options={laboralData.otherAmount.options}
              />
            </div>
          )}
        </div>
      </Card>

      <div className="one-button-footer background">
        <Button
          size={{ base: "md", sm: "md", md: "lg", lg: "lg", xl: "lg" }}
          fontSize={{ base: "sm", sm: "sm", md: "sm", lg: "sm", xl: "sm" }}
          fontWeight="600"
          bg="UniOrange.600"
          colorScheme="UniOrange"
          color="UniOrange.50"
          isLoading={loading}
          type="submit"
          isDisabled={!allInputFilled(laboralData)}
        >
          Siguiente
        </Button>

        {/*  <Button
          size={{ base: "md", sm: "md", md: "lg", lg: "lg", xl: "lg" }}
          fontSize={{ base: "sm", sm: "sm", md: "sm", lg: "sm", xl: "sm" }}
          fontWeight="600"
          bg="cBlueGray.700"
          colorScheme="cBlueGray"
          color="cBlueGray.50"
          onClick={() => handleCheckOCRData(true)}
          id="submit-button"
        >
          Siguiente
        </Button> */}
      </div>

      {/*   <Button
        size={{ base: "md", sm: "md", md: "lg", lg: "lg", xl: "lg" }}
        fontSize={{ base: "sm", sm: "sm", md: "sm", lg: "sm", xl: "sm" }}
        fontWeight="600"
        bg="cBlueGray.700"
        colorScheme="cBlueGray"
        color="cBlueGray.50"
        isLoading={loading}
        type="submit"
        isDisabled={!allInputFilled(laboralData)}
      >
        SIGUIENTE
      </Button> */}
    </form>
  );
});
LaboralDataForm.displayName = "LaboralDataForm";
export default LaboralDataForm;
