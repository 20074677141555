import { handleErrors } from "helpers/handleErrors";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getProducts, getLadaCodes } from "service/newNaturalClient/formFive";
import { getAllValuesFromForm } from "helpers/getAllValuesFromForm";
import { onboardingStart } from "service/auth/auth";
import { structureInfoFromBack } from "pages/NewNaturalClient/components/PepDataStep/hooks/usePepDataStep";

export const useCompanySelector = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [show, setShow] = useState(false);
  const [products, setproducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ladas, setladas] = useState([]);

  const location = useLocation();
  const initialState = {
    lada: {
      label: "",
      placeholder: "Lada",
      name: "lada",
      value: "+507",
      required: false,
      rule: (value) => {
        // if (value === "") return "Debe de completar este campo";
        return "";
      },
      visibility: "",
      type: "select",
      options: [],
    },
    product: {
      label: "Producto",
      placeholder: "Seleccione",
      name: "product",
      value: "",
      required: false,
      rule: (value) => {
        if (value === "") return "Debe de completar este campo";
        return "";
      },
      visibility: "",
      type: "select",
      options: [],
    },
    phone: {
      label: "Teléfono",
      placeholder: "Teléfono",
      name: "phone",
      value: "",
      required: false,
      rule: (value) => {
        if (value === "") return "Debe de completar este campo";
        if (value.length > 10)
          return "El teléfono no puede ser mayor a 10 dígitos";
        return "";
      },
      visibility: "",
      type: "select",
      options: [],
    },
  };
  const [values, setValues] = useState(initialState);
  const handleClick = () => setShow(!show);

  useEffect(() => {
    if (location.state !== null && ladas.length>0) {
      const formPersonal = structureInfoFromBack(location?.state, initialState);
      const ladaCode = ladas.filter(
        (res) => res.label === location.state.data.areaCode
      )[0];

      formPersonal.product.value = location.state.data.id;
      formPersonal.lada.value = ladaCode.value;

      setValues(formPersonal);
    }
  }, [location?.state, ladas]);
  const ladaCodeOptions = (docTypes) => {
    const cleanDocs = docTypes.map((res) => {
      return { label: res.dialCode, value: res.id };
    });
    return cleanDocs;
  };


/* Se encarga de obtener la lista de productos y los códigos 
Lada desde el servidor. Es una función asíncrona que se define
dentro del useEffect y se ejecuta una vez cuando el componente se monta.*/

  useEffect(() => {
    const handleGetProducts = async () => {
      try {
        const result = await getProducts();
        const result2 = await getLadaCodes();
        setproducts(
          result.data.map((res) => {
            return { label: res.name, value: res.idProduct };
          })
        );
        setladas(ladaCodeOptions(result2.data));
      } catch (error) {
 
      }
    };
    handleGetProducts();
  }, []);

  /*Se encarga de manejar el envío del formulario. 
  Esta función se ejecuta cuando el usuario envía el formulario*/


  const handleSubmitForm = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const BODY = { ...getAllValuesFromForm(values) };
      const ladaCode = ladas.find((res) => res.value === parseInt(BODY.lada)) || { label: "+507" };

      console.log("LADACODE:", ladaCode);
      if (!ladaCode) {
        throw new Error("Invalid Lada Code");
      }

      BODY.phone = `${ladaCode.label}-${BODY.phone}`;
      const result = await onboardingStart(BODY);
      sessionStorage.setItem("phone", BODY.phone);
      
      if (result.data.validatePhone) {
        navigate("/ingresar-pin-telefonico", { state: result.data });
      } else if (result.data.nextView === "1") {
        navigate("/pasos", { state: result.data });
      } else {
        navigate("/persona-natural/registro", { state: result.data });
      }
    } catch (error) {
      handleErrors(error);
    } finally {
      setLoading(false);
    }
  };
  return {
    handleSubmitForm,
    open,
    setOpen,
    values,
    loading,
    show,
    handleClick,
    products,
    setValues,
    ladas,
  };
};
