import { memo, Suspense, lazy } from "react";
import {
  Button,
  FormControl,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import "./LoginForm.scss";
import { EmailIcon } from "@chakra-ui/icons";
import { useLogin } from "../../hooks/useLogin";
import StepDots from "components/StepDots/StepDots";

// Carga diferida del componente WhiteScreenLoader
const MyLazyComponent = lazy(() =>
  import("components/WhiteScreenLoader/WhiteScreenLoader")
);

//Formulario de logink, en este formulario se ingresa el correo electrónico al cual llega la invitación del Onboarding
const LoginForm = memo(() => {
  // Uso del hook personalizado useLogin para obtener las funciones y estados necesarios
  const { onChange, values, handleLogin, loading } = useLogin();

  return (
    <div className="login-form">
      <Suspense fallback={null}>{loading && <MyLazyComponent />}</Suspense>
      <div className="body-login">
        <form className="container-form" onSubmit={handleLogin}>
          <Text color="cBlueGray.700" fontSize="md" fontWeight="500">
            Ingresa el correo electrónico con el cual deseas realizar tu solicitud.
          </Text>
          <FormControl>
            {/* <FormLabel color="cBlueGray.800">Email</FormLabel> */}
            <InputGroup>
              <InputRightElement
                height="48px"
                fontSize="20px"
                pointerEvents="none"
                // eslint-disable-next-line react/no-children-prop
                children={<EmailIcon color="cBlueGray.800" />}
              />
              <Input
                fontWeight="400"
                name="username"
                value={values.username}
                onChange={onChange}
                variant="filled"
                className="input-person"
                placeholder="Ingresa tu email"
                type="email"
                height="48px"
              />
            </InputGroup>
          </FormControl>
          <FormControl>
            <Button
              height="48px"
              size="md"
              fontSize={{ base: "md", sm: "md", md: "md", lg: "md", xl: "md" }}
              fontWeight="600"
              bg="UniOrange.600"
              colorScheme="UniOrange"
              color="UniOrange.50"
              width="100%"
              type="submit"
              isLoading={loading}
              disabled={loading}
            >
              Enviar
            </Button>
          </FormControl>
          <StepDots steps={[1, 2]} actualStep={1} />
        </form>
      </div>
    </div>
  );
});
LoginForm.displayName = "LoginForm";
export default LoginForm;
