
export const StepsFaceIcon = ({ width = 24, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3761 19.4156C14.5113 19.7067 14.3849 20.0523 14.0938 20.1875C12.7485 20.8121 11.4157 20.9268 10.3237 20.4256C9.21599 19.9171 8.49382 18.839 8.2566 17.3761L9.40388 17.19C9.59553 18.3719 10.1369 19.061 10.8085 19.3693C11.4958 19.6847 12.4579 19.6656 13.6043 19.1333C13.8954 18.9981 14.241 19.1245 14.3761 19.4156Z"
        fill="#f75c00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7925 13.5849V11.2075H13.8491V14.6415H10.9434V13.5849H12.7925Z"
        fill="#f75c00"
      />
      <path
        d="M17.3964 8.56613C17.3964 9.14967 16.9234 9.62273 16.3398 9.62273C15.7563 9.62273 15.2832 9.14967 15.2832 8.56613C15.2832 7.98258 15.7563 7.50952 16.3398 7.50952C16.9234 7.50952 17.3964 7.98258 17.3964 8.56613Z"
        fill="#f75c00"
      />
      <path
        d="M8.71721 8.56613C8.71721 9.14967 8.24415 9.62273 7.66061 9.62273C7.07706 9.62273 6.604 9.14967 6.604 8.56613C6.604 7.98258 7.07706 7.50952 7.66061 7.50952C8.24415 7.50952 8.71721 7.98258 8.71721 8.56613Z"
        fill="#f75c00"
      />
    </svg>
  );
};
