export const TinCheck = ({ width = 12, height = 10 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.86339 7.58336L1.08339 4.80336L0.136719 5.74336L3.86339 9.47003L11.8634 1.47003L10.9234 0.530029L3.86339 7.58336Z"
        fill="currentColor"
      />
    </svg>
  );
};
