import { Button, Heading, Text } from "@chakra-ui/react";
import { memo, useEffect } from "react";
import "./RiskStep.scss";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { setNewNaturalStep } from "redux/features/newNaturalClient";
import Icon from "components/icons";
import Navbar from "components/Navbar/Navbar";
import { useNavigate } from "react-router-dom";

const RiskStep = memo(() => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { trackData } = useSelector((store) => store.newNaturalClient);

  const handleRedirect = () => {
    window.location.href = trackData?.data?.href;
  };

  useEffect(() => {
    const getFirtsTimeReload = () => {
      const firstTime = sessionStorage.getItem("first-newtech");
      const mode = sessionStorage.getItem("mode");
      if (!firstTime) {
        sessionStorage.setItem("first-newtech", "true");
      } else {
        navigate(`/mode/${mode}`);
      }
    };
    getFirtsTimeReload();
    return () => {
      dispatch(setNewNaturalStep(1));
      getFirtsTimeReload();
    };
  }, []);
  return (
    <div className="body">
      {/* <Navbar noBackbutton title="Frecuencia de Movimientos" /> */}

      <div className="head-container">
        <CheckCircleIcon w={70} h={70} color="cTeal.500" />
        <Heading
          as="h2"
          size={{ base: "lg", sm: "lg", md: "lg", lg: "lg", xl: "lg" }}
          color={"cBlueGray.700"}
        >
          ¡Listo!
        </Heading>
        <Text
          size={{ base: "md", sm: "md", md: "md", lg: "md", xl: "md" }}
          color={"cBlueGray.500"}
        >
          Estimado cliente hemos recibido su solicitud con éxito y próximamente
          le estaremos contactando 
        </Text>
      </div>

      <div className="middle-container">
        <Text
          size={{ base: "md", sm: "md", md: "md", lg: "md", xl: "md" }}
          color={"cBlueGray.500"}
        >
          Tu número de trámite es:
        </Text>
        <Button
          rightIcon={<Icon name="CopyIcon" />}
          colorScheme="UniOrange"
          variant="outline"
        >
          {trackData?.data?.request}
        </Button>
      </div>

      <div className="one-button-footer background">
        <Button
          size={{ base: "md", sm: "md", md: "lg", lg: "lg", xl: "lg" }}
          fontSize={{ base: "sm", sm: "sm", md: "sm", lg: "sm", xl: "sm" }}
          fontWeight="600"
          bg="UniOrange.600"
          colorScheme="UniOrange"
          color="UniOrange.50"
          onClick={handleRedirect}
        >
          {trackData?.data?.message}
        </Button>
      </div>
    </div>
  );
});
RiskStep.displayName = "RiskStep";
export default RiskStep;
