import { memo, useState } from "react";
import { Text, Heading, Button } from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";
import { Card, Col, Modal, Row } from "antd";
import CRadioInput from "components/inputs/RadioInput/CRadioInput";
import { useKycClient } from "../hooks/useKycClient";
const KycClient = memo(({ kycClient, setKycClient }) => {
  useKycClient({ kycClient, setKycClient });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <Card
      title={
        <div className="row" style={{ justifyContent: "space-between" }}>
          <div className="col xs">
            <Heading
              as="h4"
              fontSize={{ base: "lg", sm: "lg", md: "lg", lg: "lg", xl: "lg" }}
              color={"cBlueGray.700"}
              style={{ margin: "0" }}
            >
              Declaración PEP
            </Heading>
          </div>
          <div className="col xs" style={{ alignItems: "flex-end" }}>
            <Text
              onClick={showModal}
              style={{ cursor: "pointer" }}
              fontSize={{
                base: "md",
                sm: "md",
                md: "md",
                lg: "md",
                xl: "md",
              }}
              color={"UniOrange.600"}
            >
              ¿Qué es? <InfoIcon />
            </Text>
          </div>
        </div>
      }
    >
      <form className="form">
        <div className="row wrap">
          <div className="col">
            <CRadioInput
              label={kycClient.pep.label}
              name={kycClient.pep.name}
              value={kycClient.pep.value}
              onChange={setKycClient}
              placeholder={kycClient.pep.placeholder}
              error={kycClient.pep.error}
              radioOptions={kycClient.pep.options}
              rule={kycClient.pep.rule}
            />
            {/* <CSelect
              label={kycClient.pep.label}
              name={kycClient.pep.name}
              value={kycClient.pep.value}
              onChange={setKycClient}
              placeholder={kycClient.pep.placeholder}
              error={kycClient.pep.error}
              options={kycClient.pep.options}
              rule={kycClient.pep.rule}
            /> */}
            <Modal centered footer={false} closeIcon={<></>} open={isModalOpen}>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <InfoIcon fontSize={24} color={"cBlueGray.400"} />
                </Col>

                <Col span={24}>
                  <Heading
                    as="h4"
                    fontSize={{
                      base: "lg",
                      sm: "lg",
                      md: "lg",
                      lg: "lg",
                      xl: "lg",
                    }}
                    color={"cBlueGray.700"}
                    style={{ margin: "0" }}
                  >
                    Conoce a tu cliente KYC
                  </Heading>
                </Col>
                <Col span={24}>
                  <Text fontSize="md">
                  (PEP), Persona Expuesta Políticamente. Es aquella que tiene (o tuvo) una función pública relevante, puede ser un funcionario importante de un órgano ejecutivo, legislativo, judicial o militar de un gobierno nacional o extranjero.
                  </Text>
                </Col>
                <Col span={24}>
                  <Row justify="end">
                    <Button
                      size={{
                        base: "md",
                        sm: "md",
                        md: "md",
                        lg: "md",
                        xl: "md",
                      }}
                      fontSize={{
                        base: "sm",
                        sm: "sm",
                        md: "sm",
                        lg: "sm",
                        xl: "sm",
                      }}
                      fontWeight="600"
                      bg="UniOrange.600"
                      colorScheme="UniOrange"
                      color="UniOrange.50"
                      id="submit-button"
                      onClick={handleCancel}
                    >
                      Ok, gracias
                    </Button>
                  </Row>
                </Col>
              </Row>
            </Modal>
          </div>
        </div>
      </form>
    </Card>
  );
});
KycClient.displayName = "KycClient";
export default KycClient;
