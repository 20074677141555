import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { useNavigate } from "react-router-dom";
import moment from "moment";
/* import { useLocation } from "react-router-dom"; */
import {
  setFormTrackInfo,
  setNewNaturalStep,
} from "redux/features/newNaturalClient";
import { postPepInformation } from "service/newNaturalClient/formOne";
import { getAllValuesFromForm } from "helpers/getAllValuesFromForm";
import { handleErrors } from "helpers/handleErrors";
import { getPepRelatedList } from "service/newNaturalClient/formThree";
export const structureInfoFromBack = (trackData, formState) => {
  const saveInfo = trackData.data;
  const newObject = {};
  Object.entries(formState).map(([key, value]) => {
    const newValue = value;
    newValue.value = saveInfo[key];
    if (value.type === "date")
      newValue.value = moment(saveInfo[key]).format("YYYY-MM-DD");
    if (value.type === "radio" && typeof saveInfo[key] === "boolean")
      newValue.value = saveInfo[key] ? "yes" : "no";
    newObject[key] = newValue;
  });
  return newObject;
};

export const relationOptions = (relationship) => {
  const cleanProvinces = relationship.map((res) => {
    return { label: res.name, value: res.id };
  });
  return cleanProvinces;
};

export const usePepDataStep = () => {
  const initialState3 = {
    pep: {
      label:
        "Indique si usted es PEP o mantiene relación cercana con algún PEP (persona políticamente expuesta)",
      placeholder: "Seleccione",
      name: "pep",
      value: "",
      required: false,
      error: "",
      visibility: "",
      type: "select",
      options: [],
      rule: (value) => {
        if (value === "") return "Debe de completar este campo";
        return "";
      },
    },
  };
  const initialStatePep = {
    positionPep: {
      label: "Indique el cargo que lo convirtió en PEP",
      placeholder: "Cargo",
      name: "positionPep",
      value: "",
      required: false,
      error: "",
      visibility: "",
      type: "text",
      rule: (value) => {
        const regex = /^[A-Za-zÀ-ÿ\u00f1\u00d1]+$/; 
        if (value === "") return "Debe de completar este campo";
        if (value.length > 250)
          return "La dirección solo puede tener un máximo de 250 caracteres";
        if (!regex.test(value)) return "El campo solo puede contener letras";
        return "";
      },

    },
    DateStart: {
      label: "Fecha inicio del cargo",
      placeholder: "Fecha inicio",
      name: "DateStart",
      value: "",
      required: false,
      error: "",
      visibility: "",
      type: "date",
      max: moment().format("YYYY-MM-DD"),
      rule: (value) => {
        if (value === "") return "Debe de completar este campo";
        return "";
      },
    },
    DateEnd: {
      label: "Fecha fin del cargo",
      placeholder: "fecha inicio",
      name: "DateEnd",
      value: "",
      required: false,
      error: "",
      visibility: "",
      type: "date",
      //max:moment().format('YYYY-MM-DD'),
      rule: (value) => {
        if (value === "") return "Debe de completar este campo";
        return "";
      },
    },
    actualyOnCharge: {
      label: "",
      placeholder: "Actualmente desempeño este cargo",
      name: "actualyOnCharge",
      value: false,
      required: false,
      error: "",
      visibility: "",
      type: "",
    },
  };
  const initialStateRelatedPep = {
    relationship: {
      label: "Relación con la persona que lo convirtió en PEP",
      placeholder: "Seleccione",
      name: "relationship",
      value: "",
      required: false,
      error: "",
      visibility: "",
      type: "",
      options: [],
      rule: (value) => {
        if (value === "") return "Debe de completar este campo";
        return "";
      },
    },
    pepPerson: {
      label: "Nombre de la persona políticamente expuesta",
      placeholder: "Nombre",
      name: "pepPerson",
      value: "",
      required: false,
      error: "",
      visibility: "",
      type: "text",
      rule: (value) => {
        const regex = /^[A-Za-zÀ-ÿ\u00f1\u00d1]+$/; //Permite letras con acentos
        if (value === "") return "Debe de completar este campo";
        if (!regex.test(value)) return "El campo solo puede contener letras";
        return "";
      },
    },
    positionPep: {
      label: "Cargo desempeñado por la persona que lo convirtió en PEP",
      placeholder: "Cargo",
      name: "positionPep",
      value: "",
      required: false,
      error: "",
      visibility: "",
      type: "text",
      rule: (value) => {
        const regex = /^[A-Za-zÀ-ÿ\u00f1\u00d1]+$/; //Permite letras con acentos
        if (value === "") return "Debe de completar este campo";
        if (!regex.test(value)) return "El campo solo puede contener letras";
        return "";
      },
    },
  };
  /*  const location = useLocation(); */
  /*   const [personalValues, setPersonalValues] = useState(INITIAL_STATE);
  const [homeAdress, setHomeAdress] = useState(initialState2); */
  const [kycClient, setKycClient] = useState(initialState3);
  const [pepValues, setpepValues] = useState(initialStatePep);
  const [relatedPepValues, setrelatedPepValues] = useState(
    initialStateRelatedPep
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { trackData } = useSelector((store) => store.newNaturalClient);

  const handleInitialStateInfo = () => {
    /*    const formPersonal = structureInfoFromBack(trackData, INITIAL_STATE);
    const formAdress = structureInfoFromBack(trackData, initialState2); */
    const formKycClient = structureInfoFromBack(trackData, initialState3);
    const formPep = structureInfoFromBack(trackData, initialStatePep);
    const formRelatedPep = structureInfoFromBack(
      trackData,
      initialStateRelatedPep
    );
    /*     setPersonalValues(formPersonal);
    setHomeAdress(formAdress); */
    setKycClient(formKycClient);
    setpepValues(formPep);
    setrelatedPepValues(formRelatedPep);
  };

  useEffect(() => {
    const handleGetInitialState = async () => {
      try {
        const result = await getPepRelatedList();
        setrelatedPepValues((prev) => {
          const { relationship } = prev;
          return {
            ...prev,
            relationship: {
              ...relationship,
              options: relationOptions(result.data),
            },
          };
        });
      } catch (error) {
        handleErrors(error);
      }
    };
    window.scrollTo(0, 0);

    handleGetInitialState();
  }, []);

  useEffect(() => {
    if (Object.keys(trackData.data).length > 1) handleInitialStateInfo();
    return () => {
      /*  setPersonalValues(INITIAL_STATE);
      setHomeAdress(initialState2); */
      setKycClient(initialState3);
      setpepValues(initialStatePep);
      setrelatedPepValues(initialStateRelatedPep);
    };
  }, [trackData.data]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (kycClient.pep.value === "1")
      setpepValues((prev) => {
        const inputObject = prev.DateEnd;
        let newData = { ...prev };
        if (pepValues.actualyOnCharge.value) {
          newData = {
            ...prev,
            DateEnd: {
              ...inputObject,
              value: "",
              disabled: true,
              rule: (value) => {
                

                return "";
              },
            },
          };
        } else {
          newData = {
            ...prev,
            DateEnd: {
              ...inputObject,
              value: inputObject.value ?? "",
              disabled: false,
              rule: (value) => {
                if (value === "") return "Debe de completar este campo";
                return "";
              },
            },
          };
        }
        return newData;
      });
  }, [pepValues.actualyOnCharge.value, kycClient.pep.value]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  

    try {
      /*       const requestNumber =
        location?.state?.requestNumber ?? location?.state?.numberRequest; */
      let values = { ...getAllValuesFromForm(kycClient) };
      if (kycClient.pep.value === "1") {
        values = {
          ...getAllValuesFromForm({ ...kycClient, ...pepValues }),
        };
      }
      if (kycClient.pep.value === "3") {
        values = {
          ...getAllValuesFromForm({ ...kycClient, ...relatedPepValues }),
        };
      }
      /*       const formData = new FormData();
      Object.entries(values).forEach(([key, value]) => {
        formData.append(key, value);
      });
      formData.append("mode", mode.toString());
      if (requestNumber) formData.append("requestNumber", requestNumber); */
      const res = await postPepInformation(values);
      dispatch(setNewNaturalStep(parseInt(res.data.nextView)));
      dispatch(setFormTrackInfo(res.data));
      navigate(".", { state: res.data });
    } catch (error) {
      handleErrors(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    handleSubmit,
    kycClient,
    setKycClient,
    pepValues,
    setpepValues,
    relatedPepValues,
    setrelatedPepValues,
  };
};
