import { memo } from "react";
import Logo from "../../assets/logos/logo-cooperativa.svg";
import "./MainTemplate.scss";
import { Heading, Text } from "@chakra-ui/react";

const MainTemplate = memo(({ children, title, subtitle, bodyBorderless }) => {
  return (
    <div className="main-template">
      <div className="logo-container">
        <img src={Logo} alt="bnp-logo" />
      </div>
      {(title || subtitle) && (
        <div className="title">
          {title && (
            <Heading color="cBlueGray.800" as="h3" size="md" style={{
              fontFamily: "poppins, saint-serif",
              paddingBottom: "8px"
            }}>
              {title}
            </Heading>
          )}
          {subtitle && (
            <Text className="subtitle" color="cBlueGray.800">
              {subtitle}
            </Text>
          )}
        </div>
      )}

      <div
        className="body-template"
        data-variation={bodyBorderless ? "borderless" : ""}
      >
        {children}
      </div>
    </div>
  );
});
MainTemplate.displayName = "MainTemplate";
export default MainTemplate;
