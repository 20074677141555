import { memo } from "react";
import { Button } from "@chakra-ui/react";
import "./NaturalClientForm1.scss";
import { usePepDataStep } from "./hooks/usePepDataStep";
import KycClient from "./components/KycClient";
import { allInputFilled } from "helpers/validateAllInputsFilled";
import PepForm from "../PepStep/components/PepForm";
import RelatedPepForm from "../RelatedPepStep/components/RelatedPepForm";
import Navbar from "components/Navbar/Navbar";

const PepDataStep = memo(() => {
  const {
    /*     personalValues,
    setPersonalValues,
    homeAdress,
    setHomeAdress, */
    relatedPepValues,
    setrelatedPepValues,
    pepValues,
    setpepValues,
    kycClient,
    setKycClient,
    handleSubmit,
    loading,
  } = usePepDataStep();

  const buttonDisable = () => {
    if (kycClient.pep.value === "1") {
      return !allInputFilled({ ...kycClient, ...pepValues });
    }
    if (kycClient.pep.value === "3") {
      return !allInputFilled({ ...kycClient, ...relatedPepValues });
    }
    return !allInputFilled({ ...kycClient });
  };

  return (
    <form onSubmit={handleSubmit} className="natural-client-form step1">
      <Navbar title="PEP"/>

      {/*   <PersonalData
        personalValues={personalValues}
        setPersonalValues={setPersonalValues}
      />
      <HomeAdress homeAdress={homeAdress} setHomeAdress={setHomeAdress} /> */}
      <KycClient kycClient={kycClient} setKycClient={setKycClient} />

      {kycClient.pep.value === "1" && (
        <PepForm pepValues={pepValues} setpepValues={setpepValues} />
      )}
      {kycClient.pep.value === "3" && (
        <RelatedPepForm
          relatedPepValues={relatedPepValues}
          setrelatedPepValues={setrelatedPepValues}
        />
      )}

      <div className="one-button-footer background">
        <Button
          size={{ base: "md", sm: "md", md: "lg", lg: "lg", xl: "lg" }}
          fontSize={{ base: "sm", sm: "sm", md: "sm", lg: "sm", xl: "sm" }}
          fontWeight="600"
          bg="UniOrange.600"
          colorScheme="UniOrange"
          color="UniOrange.50"
          type="submit"
          isLoading={loading}
          id="submit-button"
          /*      isDisabled={!allInputFilled({...personalValues,...homeAdress,...kycClient})} */
          isDisabled={buttonDisable()}
        >
          Siguiente
        </Button>
      </div>
    </form>
  );
});
PepDataStep.displayName = "PepDataStep";
export default PepDataStep;
