import { memo } from "react";
import { Button } from "@chakra-ui/react";
import { useFinancialProfileHook } from "../hooks/useFinancialProfileHook";
import TransactionForm from "./TransactionForm";
import { allInputFilledArray } from "helpers/validateAllInputsFilled";
import Navbar from "components/Navbar/Navbar";

export const checkIfHaveRepeatValues = (form) => {
  let repeatValues = false;
  let countObject = {};

  for (let x = 0; x < form.length; x++) {
    if (form[x].recieve_transaction_type.value === "") {
      break;
    }
    const theKey = `${form[x].recieve_transaction_type.value}`;
    if (!countObject[theKey]) {
      countObject = { ...countObject, [theKey]: 0 };
    }
    countObject = { ...countObject, [theKey]: countObject[theKey] + 1 };
  }

  const entriesForHash = Object.entries(countObject);
  for (let x = 0; x < entriesForHash.length; x++) {
    const [, value] = entriesForHash[x];
    if (value > 1) {
      repeatValues = true;
      break;
    }
  }
  return repeatValues;
};

const FinancialProfile = memo(() => {
  const { reciveMoney, setreciveMoney, handleSubmit, loading } =
    useFinancialProfileHook();
  return (
    <form className="form" onSubmit={handleSubmit}>
      <Navbar title="Frecuencia de movimientos" />
      <div className="natural-client-form step1">
        <TransactionForm
          title={"Frecuencia de movimientos"}
          formtype={"recive"}
          reciveMoney={reciveMoney}
          setreciveMoney={setreciveMoney}
          checkIfHaveRepeatValues={checkIfHaveRepeatValues}
        />
        <Button
          size={{ base: "md", sm: "md", md: "lg", lg: "lg", xl: "lg" }}
          fontSize={{ base: "sm", sm: "sm", md: "sm", lg: "sm", xl: "sm" }}
          fontWeight="600"
          bg="UniOrange.600"
          colorScheme="UniOrange"
          color="UniOrange.50"
          type="submit"
          isLoading={loading}
          isDisabled={
            !allInputFilledArray(reciveMoney) ||
            checkIfHaveRepeatValues(reciveMoney)
          }
        >
          Finalizar
        </Button>
      </div>
    </form>
  );
});
FinancialProfile.displayName = "FinancialProfile";
export default FinancialProfile;
