export const TinChevron = ({ width = 6, height = 8 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 6 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.530029 7.06L3.58336 4L0.530029 0.94L1.47003 0L5.47003 4L1.47003 8L0.530029 7.06Z"
        fill="currentColor"
      />
    </svg>
  );
};
